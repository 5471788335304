@import "../node_modules/alertifyjs/build/css/alertify.min.css";
@import "../node_modules/alertifyjs/build/css/themes/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css?family=Montserrat:500,700");
/* @import "../node_modules/ng-select/ng-select/themes/default.theme.css"; */
@import "~@ng-select/ng-select/themes/default.theme.css";
/* You can add global styles to this file, and also import other style files */

/* @import '../node_modules/tinymce/lightgray/skin.min.css'; */
/* @import '../node_modules/tinymce/skins/content/default/content.min.css'; */
@import '~tinymce/skins/lightgray/skin.min.css';



@font-face {
    font-family: "montserratmedium";
    /*src: url('fonts/montserrat-regular-webfont.woff2') format('woff2'),*/
    /*     url('fonts/montserrat-regular-webfont.woff') format('woff');*/
    src: url("fonts/Montserrat-Medium.woff2") format("woff2"), url("fonts/Montserrat-Medium.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "montserratmedium";
}

label,
p,
span,
div {
    font-family: "montserratmedium";
}

.text-blue {
    color: #26a9e0;
}

.box-shadow {
    border: 1px solid transparent !important;
    -webkit-box-shadow: 0 3px 7px 0 #a8a8a8 !important;
    -moz-box-shadow: 0 3px 7px 0 #a8a8a8 !important;
    box-shadow: 0 3px 7px 0 #a8a8a8 !important;
}

.marker {
    background-color: yellow !important;
}

.height100 {
    height: 100% !important;
    max-height: 100% !important;
}

.height815px {
    height: 815px !important;
}

/*padding custom classes*/

.datePickerBtnDefaultPadding {
    padding: 4px 14px !important;
}

.datePickerBtnDefaultPadding i {
    margin-top: 4px !important;
}

.btnPaddingDefault {
    padding: 7px 14px !important;
}

.noPadding {
    padding: 0px !important;
}

.padding15 {
    padding: 15px !important;
}

.padding5 {
    padding: 5px !important;
}

.padding10 {
    padding: 10px !important;
}

.paddingTB10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.paddingTop10 {
    padding-top: 10px !important;
}

.paddingRL0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.paddingRL15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
}

.paddingRL30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
}

.paddingR15 {
    padding-right: 15px !important;
}

.paddingRL40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
}

.paddingBottom25 {
    padding-bottom: 25px !important;
}

.paddingBottom30 {
    padding-bottom: 30px !important;
}

.paddingTB25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
}

.paddingBottom0 {
    padding-bottom: 0px !important;
}

.paddingBottom5 {
    padding-bottom: 5px !important;
}

.paddingBottom10 {
    padding-bottom: 10px !important;
}

.paddingBottom15 {
    padding-bottom: 15px !important;
}

.paddingTop40 {
    padding-top: 40px !important;
}

.paddingTop20 {
    padding-top: 20px !important;
}

.paddingTop0 {
    padding-top: 0px !important;
}

.paddingTop30 {
    padding-top: 30px !important;
}

.paddingRight25 {
    padding-right: 25px !important;
}

.paddingLeft25 {
    padding-left: 25px !important;
}

.paddingLeft15 {
    padding-left: 15px !important;
}

.paddingRight0 {
    padding-right: 0px !important;
}

.defaultBtnPadding {
    padding: 6px 12px !important;
}

.paddingLeft5 {
    padding-left: 5px !important;
}

.paddingLeft0 {
    padding-left: 0px !important;
}

.paddingLeft2p {
    padding-left: 2% !important;
}

/*end padding custom classes*/

/*margin custom classes*/

.modal-dialog {
    margin-top: 10% !important;
}

.noMargin {
    margin: 0px !important;
}

.marginBottom10p {
    margin-bottom: 10% !important;
}

.marginBottom25 {
    margin-bottom: 25px !important;
}

.marginBottom20 {
    margin-bottom: 20px !important;
}

.marginBottom30 {
    margin-bottom: 30px !important;
}

.marginBottom15 {
    margin-bottom: 15px !important;
}

.marginTop10 {
    margin-top: 10px !important;
}

.marginTop15 {
    margin-top: 15px !important;
}

.marginTop30 {
    margin-top: 30px !important;
}

.marginTop25 {
    margin-top: 25px !important;
}

.marginTop20 {
    margin-top: 20px !important;
}

.marginTop0 {
    margin-top: 0px !important;
}

.marginBottom0 {
    margin-bottom: 0px !important;
}

.marginBottom5 {
    margin-bottom: 5px !important;
}

.marginBottom6 {
    margin-bottom: 6px !important;
}

.marginBottom10 {
    margin-bottom: 10px !important;
}

.marginTop5 {
    margin-top: 5px !important;
}

.marginTB18 {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
}

.marginTB15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.marginLR15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.marginLeft15 {
    margin-left: 15px !important;
}

.marginTB10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.margin-right0 {
    margin-right: 0px;
}

.marginRight15 {
    margin-right: 15px;
}

.marginTop2p {
    margin-top: 2% !important;
}

/*end margin custom classes*/

.searchField .input-icon.right .btn.fa.fa-times {
    font-size: 20px;
    margin-top: 7px;
}

.back-btn a {
    float: right;
    text-decoration: none;
    color: #139cd7;
}

.top36px {
    top: 36px !important;
}

/*fonts custom classes*/

.floatRight {
    float: right;
}

.fontSize25px {
    font-size: 25px;
}

.font-weight700 {
    font-weight: 700 !important;
}

.font-weight600 {
    font-weight: 600 !important;
}

.font-weight400 {
    font-weight: 400 !important;
}

.font-weight500 {
    font-weight: 500 !important;
}

.fontSize19 {
    font-size: 19px !important;
}

.fontSize18 {
    font-size: 18px !important;
}

.fontSize14 {
    font-size: 14px !important;
}

.fontSize12 {
    font-size: 12px !important;
}

.fontSize15 {
    font-size: 15px !important;
}

.fontSize16 {
    font-size: 16px !important;
}

.fontSize17 {
    font-size: 17px !important;
}

.fontSize21 {
    font-size: 22px !important;
}

.fontSize22 {
    font-size: 22px !important;
}

.fontSize30 {
    font-size: 30px !important;
}

.fontSize26 {
    font-size: 26px !important;
}

.fontSize36 {
    font-size: 36px !important;
}

.fontSize40 {
    font-size: 40px;
}

.gridClass {
    display: grid !important;
}

.selfCenterClass {
    align-self: center;
}

.margin0 {
    margin: 0 !important;
}

/*fonts custom classes*/

.btn-teel-blue {
    background-color: #139cd7 !important;
    border-color: #139cd7 !important;
    color: #ffff !important;
}

.btn-teel-blue:hover {
    color: #fefeff !important;
    text-decoration: none !important;
}

button.calendar,
button.calendar:active {
    width: 2.75rem;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=) !important;
    background-repeat: no-repeat;
    background-size: 23px;
    background-position: center;
}

.task_title {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 26px;
    line-height: 138.4%;
    letter-spacing: 0.1px;
    color: #5a5a65;
}

.gridClass {
    display: grid !important;
}

.selfCenterClass {
    align-self: center;
}

.margin0 {
    margin: 0 !important;
}

.bg-light {
    background-color: #edf0f4 !important;
}

.displayInline {
    display: inline-block;
}

.form-control:focus,
.custom-select:focus,
.btn:focus,
.btn.focus {
    box-shadow: none !important;
}

::-webkit-scrollbar {
    width: 0.4em;
    height: 1em;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #999;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 10px;
    background-color: #f5f5f5;
}

.selected-list .c-list .c-token .c-remove {
    top: 40% !important;
}

.standard_black {
    color: #797979 !important;
}

.mat-drawer-content {
    overflow-x: hidden !important;
}

.primaryText {
    color: #4d4d4d;
}
.mainHeading {
    color: #4d4d4d;
}
.subHeading {
    color: #4d4d4d;
}
.flexClass {
    display: flex;
}
.alignSelfCenter {
    align-self: center;
}
.primaryBtn {
    border-radius: 8px;
    border: none;
    color: white;
    background: #139cd7;
}
.primaryBtn:hover {
    opacity: 0.8;
}
.primaryBtn:focus {
    outline: none;
}
.secondaryBtn {
    border-radius: 8px;
    border: 1px solid #139cd7;
    color: #139cd7;
    background: transparent;
}
.secondaryBtn:focus {
    outline: none;
}
.pointer{
    cursor: pointer;
}

@media only screen and (min-width: 991px) {
    .primaryBtn,
    .secondaryBtn {
        font-size: 0.91145834vw;
        line-height: 1;
        padding: 0.78125vw 1.56vw;
    }
    .primaryText {
        font-size: 0.91145834vw;
    }
    .mainHeading {
        font-size: 1.32vw;
    }
    .subHeading {
        font-size: 1.171875vw;
    }
    .smallText {
        font-size: 0.78125vw;
    }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
    .primaryBtn,
    .secondaryBtn {
        font-size: 1.3vw;
        line-height: 1.5;
        padding: 0.8vw 2vw;
    }
    .primaryText {
        font-size: 1.3vw;
    }
    .mainHeading {
        font-size: 1.6vw;
    }
    .subHeading {
        font-size: 1.47vw;
    }
    .smallText {
        font-size: 1.08vw;
    }
}
@media only screen and (max-width: 767px) and (min-width: 576px) {
    .primaryBtn,
    .secondaryBtn {
        font-size: 1.6vw;
        padding: 0.9vw 2.5vw;
    }
    .primaryText {
        font-size: 1.6vw;
    }
    .mainHeading {
        font-size: 1.9vw;
    }
    .subHeading {
        font-size: 1.77vw;
    }
    .smallText {
        font-size: 1.38vw;
    }
}
@media only screen and (max-width: 575px) {
    .primaryBtn,
    .secondaryBtn {
        font-size: 2.6vw;
        padding: 1.1vw 3.5vw;
    }
    .primaryText {
        font-size: 2.6vw;
    }
    .mainHeading {
        font-size: 2.9vw;
    }
    .subHeading {
        font-size: 2.77vw;
    }
    .smallText {
        font-size: 2.38vw;
    }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
